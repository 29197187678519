import { isVolatile } from "@/__main__/constants.mjs";
import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Silent, Union } from "@/__main__/data-model.mjs";
import { KryptoniteErrorModel } from "@/feature-crypto/models/model-kryptonite-error.mjs";

const subscriptionModel = {
  startAt: Date,
  endAt: Date,

  renews: Silent(Boolean),
} satisfies ModelDefinition;

export const KryptoniteSubscriptionModel = createModel(subscriptionModel);

export type KryptoniteSubscription = FromModel<
  typeof KryptoniteSubscriptionModel
>;

const responseModel = Union([
  KryptoniteSubscriptionModel,
  KryptoniteErrorModel,
]);

export const KryptoniteSubscriptionResponseModel = createModel(
  responseModel,
).transform(responseModel, (data) => {
  data[isVolatile] = true;
  return data;
});

export type KryptoniteSubscriptionResponse = FromModel<
  typeof KryptoniteSubscriptionResponseModel
>;
