import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { initAuth } from "@/feature-auth/utils/auth-actions.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { kryptonite } from "@/feature-crypto/kryptonite.mjs";
import { KryptoniteSubscriptionResponseModel } from "@/feature-crypto/models/model-kryptonite-subscription.mjs";

export default async function fetchSubscription(): Promise<void> {
  await initAuth();

  const user = readState.user;
  const bearerToken = await getBearerToken();
  const isLoggedIn = Boolean(user && bearerToken);
  if (!isLoggedIn) return;

  await getData(
    kryptonite.request("GET", `/subscriptions/me`),
    KryptoniteSubscriptionResponseModel,
    ["crypto", "subscription"],
    {
      shouldSkipDBRead: true,
    },
  );
}
